@import "../../../assets/scss/breakpoints";
@import "../../../assets/scss/mixins";

.container {
  margin-top: 52px;
  margin-bottom: 40px;
  @include respond-to(m) {
    margin-top: 90px;
    margin-bottom: 70px;
  }
}

.title {
  @include title(24px, 32px, 32px);
  line-height: 33px;
  margin-bottom: 20px;
  @include respond-to(m) {
    margin-bottom: 50px;
  }
}

.background {
  width: 100vw;
  box-sizing: border-box;
  background: #FBFBFF;
  padding: 15px;
  @include respond-to(m) {
    padding: 40px;
  }
  @include respond-to(l) {
    padding: 50px;
  }
}

.block {
  @include flexDirection(column, row);
  max-width: 1184px;
  margin: 0 auto;
}

.img {
  @include flex(row, space-between, center);
  width: 100%;
  &:first-child {
    margin-bottom: 10px;
    @include respond-to(m) {
      margin-bottom: 0;
      margin-right: 25px;
    }
  }
}
