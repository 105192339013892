@import "../../../assets/scss/mixins";

.container {
  width: 100vw;
  box-sizing: border-box;
  background: #0C4744;
  color: #FBFBFF;
  padding: 30px;

  & > div {
    @include flex(column, center, center)
  }

  @include respond-to(m) {
    padding: 50px;
  }
  @include respond-to(l) {
    padding: 70px;
  }
}

.title {
  @include title(24px, 32px, 32px);
  line-height: 33px;
  margin-bottom: 30px;
  max-width: 540px;
}

.img {
  width: 77px;
  height: 64px;
  margin-bottom: 30px;
  @include respond-to(m) {
    width: 100px;
    height: 83px;
  }
  @include respond-to(l) {
    width: 130px;
    height: 108px;
  }
}

.text {
  font-size: 14px;
  line-height: 24px;
  max-width: 500px;
  text-align: center;
  @include respond-to(m) {
    font-size: 16px;
  }
}
