// breakpoints
$small: 580px;
$medium: 1024px;
$large: 1366px;

// colors
$primary_text: #333333;
$error: #FF6060;
$secondary_text: #4F4F4F;
$typography_black: #3F404C;
$typography_grey: #828282;
$primary_violet: #8E8CF0;
$primary_border: #BDBDBD;
$border_violet: #0C4744;
$background_gradient: #ECC8BC;
$primary_gradient: linear-gradient(90.6deg, #2699A0 0.47%, #C6AF5D 137.94%);
$secondary_gradient: linear-gradient(90.6deg, rgba(106, 77, 188, 0.5) 0.47%, rgba(242, 153, 74, 0.5) 137.94%);
$primary_border_gradient: linear-gradient(38.65deg, #4568DC 27.36%, #B06AB3 87.78%);
$primary_postQuiz_gradient: linear-gradient(180deg, #141333 0%, rgba(20, 19, 51, 0) 100%);
$background_secondary_gradient : linear-gradient(109.66deg, #FFFFFF 1.11%, rgba(255, 255, 255, 0.23) 115.23%);

// dimensions
$modal_padding: 20px;
