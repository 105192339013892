@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px;
  max-width: 100%;
  width: 330px;
  height: 321px;
  margin: 50px 0;
  border-radius: 20px;
  background: linear-gradient(180deg, #2D9690 0%, rgba(180, 166, 94, 0.96) 100%);
  @include respond-to(m) {
    margin: 80px 0;
    width: 709px;
    height: 439px;
  }
  @include respond-to(l) {
    padding: 50px;
    width: 864px;
    height: 483px;
  }
}

.title {
  margin-bottom: 20px;
  color: $primary_text;
  @include title(24px, 28px, 32px);
  @include respond-to(m) {
    margin-bottom: 30px;
  }
}

