@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

div.container {
  align-items: center;
  text-align: center;
  margin-top: 42px;
  @include respond-to(m) {
    margin-top: 94px;
  }
  @include respond-to(l) {
    margin-top: 60px;
    justify-content: space-between;
  }
}

.title {
  @include title;
  margin-bottom: 14px;
  @include respond-to(m) {
    margin-bottom: 30px;
  }
  @include respond-to(l) {
    margin-bottom: 20px;
  }
}

.text {
  font-size: 14px;
  margin-bottom: 30px;
  color: $secondary_text;
  line-height: 24px;
  @include respond-to(m) {
    font-size: 16px;
    margin-bottom: 70px;
  }
  @include respond-to(l) {
    max-width: 524px;
    margin: 0 auto 20px auto;
  }
}

.img {
  margin-bottom: 50px;
  width: 200px;
  @include respond-to(m) {
    width: initial;
    @include respond-to(l) {
      margin-bottom: 30px;
      height: 50%;
    }
  }
}

.link_container {
  max-width: 300px;
  margin: 0 auto;
  padding-bottom: 40px;
  @include respond-to(m) {
    max-width: 340px;
    padding-bottom: 25px;
  }
}

.link {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  text-align: center;
  justify-content: center;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: white;
}

.policy {
  font-size: 12px;
  color: $secondary_text;
  line-height: 18px;
  margin-top: 18px;
  @include respond-to(m) {
    margin-top: 56px;
    font-size: 14px;
    line-height: 24px;
  }
  @include respond-to(l) {
    margin-top: 30px;
  }
}

.inline_link {
  color: $primary_text;
}
