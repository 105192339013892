@import "src/assets/scss/breakpoints";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  line-height: 0;
}

.image {
  width: 300px;
  height: 38px;
}
